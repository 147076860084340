import { graphql, PageProps, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import { LayoutProvider, Wrapper } from "../components/layout";
import { stackStyled, Typography, useStackStyletron } from "../theme";
import { useViewPortSize } from "../hooks";
import { StackOnBreadcrumbs } from "../atoms";
import { ResponsiveContainer } from "../atoms/Containers";
import ReactMarkdown from "react-markdown";
import ReactMarkdownComponent from "../atoms/ReactMarkDownComponent";
import TitleAndSubtitle from "../atoms/Containers/TitleAndSubtitle";

const query = graphql`
  query {
    strapiTermsConditions {
      body
      title
    }
  }
`;

interface ITerms {
  strapiTermsConditions: {
    body: string;
    title: string;
  };
}

const MainContainer = stackStyled("div", (props: { isMobile: boolean }) => ({
  width: "64vw",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  paddingTop: "40px",
  paddingBottom: props.isMobile ? "10vh" : "15vh",
}));

const ContainerDropZone = stackStyled("div", () => ({
  width: "100%",
  marginTop: "10vh",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
}));

const BreadCrumbsContainer = stackStyled(
  "div",
  (props: { isMobile: boolean }) => ({
    position: "absolute",
    top: props.isMobile ? "10px" : "30px",
    left: props.isMobile ? 0 : '5%',
    width: props.isMobile ? "fit-content" : "580px",
  })
);

const MainWrapper = stackStyled(
  Wrapper,
  (props: { isMobile: boolean; isTablet?: boolean }) => ({
    display: "flex",
    maxWidth: "1280px",
    width: props.isMobile ? "95%" : props.isTablet ? "90%" : "100%",
    justifyContent: "center",
    padding: props.isMobile ? "100px 10px" : "50px 10px",
    marginLeft: "auto",
    marginRight: "auto",
    overflowX: "hidden",
    textAlign: "center",
  })
);

export const TermsOfUSe: React.FC<PageProps> = (props: PageProps) => {
  const [isMobile, isTablet] = useViewPortSize();
  const [css, theme] = useStackStyletron();
  const data = useStaticQuery<ITerms>(query);

  return (
    <LayoutProvider seo locationSearch={props.location.search}>
      <MainWrapper isMobile={isMobile} isTablet={isTablet} noScroll id="main">
        <BreadCrumbsContainer isMobile={isMobile}>
          <StackOnBreadcrumbs
            pages={[
              { title: "Home", href: "/" },
              {
                title: "Customer service",
                href: "/customer-service",
              },
              { title: "Terms of use", href: "/terms-of-use" },
            ]}
          />
        </BreadCrumbsContainer>
        <ResponsiveContainer>
          <MainContainer isMobile={isMobile}>
            <TitleAndSubtitle title={data.strapiTermsConditions.title} colortitle={"#687838"}/>
            <ReactMarkdownComponent body={data.strapiTermsConditions.body}  />
          </MainContainer>
        </ResponsiveContainer>
      </MainWrapper>
    </LayoutProvider>
  );
};

export default TermsOfUSe;
